import React, { FC, useEffect, useRef } from 'react';
import { FormAssistantChatProps } from './interfaces';
import { ChatBubble } from './ChatBubble';
import { ChatInput } from './ChatInput';
import { Role } from '@wix/ambassador-forms-assistant-v2-message/types';
import { ChatHeader } from './ChatHeader';
import { ChatActions } from './ChatActions';
import { Divider } from 'wix-ui-tpa/cssVars';
import { ProgressBar } from './ProgressBar';
import { Choices } from './Choices';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { EXPERIMENTS } from '../../../../../constants/experiments';
import Draggable from 'react-draggable';
import classnames from 'classnames';
import { createPortal } from 'react-dom';
import { DRAG_HANDLE_ID } from './ChatHeader/ChatHeader';

import { classes } from './FromAssistantChat.st.css';

export const FormAssistantChat: FC<FormAssistantChatProps> = ({
  formValues,
  aiAssistantService,
  aiAssistantState,
  onClose,
  onSend,
  submitForm,
  dataHook,
  hostId,
}) => {
  const { experiments } = useExperiments();
  const messagesElement = useRef<HTMLDivElement>(null);
  const { isMobile } = useEnvironment();

  useEffect(() => {
    const element = messagesElement?.current;
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  }, [aiAssistantState?.assistantTyping]);

  const submitUserMessage = (message: string) => {
    aiAssistantService.submitUserMessage({
      message,
      formValues,
    });
    onSend && onSend();
  };

  const lastMessageIsSkippable =
    !!aiAssistantState?.messages[0]?.context?.canBeSkipped &&
    !aiAssistantState?.assistantTyping;
  return createPortal(
    <Draggable handle={`#${DRAG_HANDLE_ID}`} disabled={isMobile}>
      <div
        className={classnames(
          isMobile ? classes.containerMobile : classes.containerDesktop,
          classes.containerCommon,
          hostId,
        )}
        data-hook={dataHook}
      >
        <ChatHeader onClose={onClose} />
        <ProgressBar
          completed={aiAssistantState.completedQuestions}
          total={aiAssistantState.totalQuestions}
        />
        <Divider />
        <div className={classes.chatContainer} ref={messagesElement}>
          {aiAssistantState.apiErrorOcurred && (
            <div className={classes.error}>Error in API</div>
          )}
          {aiAssistantState?.assistantTyping && (
            <ChatBubble creatorRole={Role.ASSISTANT} loading />
          )}
          {aiAssistantState?.messages?.map((message) => (
            <ChatBubble
              useMarkdown
              creatorRole={message.role}
              message={message.content}
              key={message.id}
              additionalContent={
                experiments.enabled(EXPERIMENTS.AI_ASSISTANT_OPTION_BUTTONS) &&
                message.context?.fieldChoices ? (
                  <Choices
                    choices={message.context.fieldChoices}
                    onSubmit={submitUserMessage}
                  />
                ) : null
              }
            />
          ))}
        </div>
        <div className={classes.inputContainer}>
          <ChatInput
            onSubmit={submitUserMessage}
            disabled={aiAssistantState.assistantTyping}
          />
        </div>
        <ChatActions
          onClose={onClose}
          submitForm={submitForm}
          enableSubmit={aiAssistantState.readyToSubmit}
          enableSkip={lastMessageIsSkippable}
          submitUserMessage={submitUserMessage}
        />
      </div>
    </Draggable>,
    document.getElementById('SITE_CONTAINER') || document.body,
  );
};
