import { FormValues, FormViewer } from '@wix/form-viewer/widget';
import React, { useEffect, useState } from 'react';
import { FormViewerWithAIProps } from './interfaces';
import { FormAssistantChat } from '../FormAssistantChat';
import { Button, ButtonPriority } from 'wix-ui-tpa/cssVars';
import { useTranslation } from '@wix/yoshi-flow-editor';
import AiIcon from '../../../../../assets/images/Glyph.svg';
import classNames from 'classnames';
import { classes } from './FormViewerWithAi.st.css';
import { usePrevious } from '../../hooks/use-previous';
import {
  AssistantBiAction,
  useAssistantBi,
} from '../../hooks/use-assistant-bi';
import { getChangedValueTargetsForBi } from './FormViewerWithAi.utils';

import styles from './FormViewerWithAi.scss';

export const FORM_VIEWER_WITH_AI_HOOKS = {
  container: 'FormViewerWithAIContainer',
  openAssistantButtonWrapper: 'FormViewerWithAIOpenAssistantButtonWrapper',
  assistantButton: 'AssistantButton',
  assistantChat: 'AssistantChat',
};

function isFormDataEqual(formData1?: FormValues, formData2?: FormValues) {
  return JSON.stringify(formData1) === JSON.stringify(formData2);
}

export const FormViewerWithAI = (props: FormViewerWithAIProps) => {
  const { aiAssistantProps, formViewerProps } = props;
  const { aiAssistantService, aiAssistantState } = aiAssistantProps;
  const { onChange, values, bi } = formViewerProps;

  const { reportAction, reportAiFieldEdit, formFillingSessionId } =
    useAssistantBi({
      bi,
      formId: formViewerProps.formId,
    });

  const [aiAssistantOpen, setAiAssistantOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const prevAssistantFormData = usePrevious(aiAssistantState?.formData);

  useEffect(() => {
    if (
      aiAssistantState?.formData &&
      values &&
      !isFormDataEqual(prevAssistantFormData, aiAssistantState?.formData) &&
      !isFormDataEqual(aiAssistantState?.formData, values)
    ) {
      onChange(aiAssistantState?.formData);
      const changedValueTargets = getChangedValueTargetsForBi(
        aiAssistantState?.formData,
        values,
      );
      changedValueTargets.length && reportAiFieldEdit(changedValueTargets);
    }
  }, [aiAssistantState?.formData]);

  const openAiAssistant = () => {
    reportAction(AssistantBiAction.open);
    setAiAssistantOpen(true);
    aiAssistantService.initThread(values);
  };

  const closeChat = () => {
    reportAction(AssistantBiAction.close);
    setAiAssistantOpen(false);
  };

  const submitForm = async () => {
    if (typeof formViewerProps.ref === 'object') {
      const submitSuccessfully = await formViewerProps.ref?.current?.submit();
      closeChat();
      if (submitSuccessfully) {
        aiAssistantService.clearThread();
      }
    }
    return;
  };

  return (
    <div
      className={classes.container}
      data-hook={FORM_VIEWER_WITH_AI_HOOKS.container}
    >
      {aiAssistantOpen && (
        <FormAssistantChat
          hostId={props.hostId}
          dataHook={FORM_VIEWER_WITH_AI_HOOKS.assistantChat}
          aiAssistantService={aiAssistantService}
          aiAssistantState={aiAssistantState}
          onSend={() => reportAction(AssistantBiAction.send)}
          submitForm={submitForm}
          formValues={values}
          onClose={closeChat}
        />
      )}
      <div>
        <div
          className={classNames(
            styles.buttonContainer,
            classes.buttonContainer,
          )}
          data-hook={FORM_VIEWER_WITH_AI_HOOKS.openAssistantButtonWrapper}
        >
          <Button
            upgrade
            priority={ButtonPriority.primary}
            onClick={openAiAssistant}
            data-hook={FORM_VIEWER_WITH_AI_HOOKS.assistantButton}
          >
            <div className={styles.buttonContent}>
              <AiIcon />
              {t('ai_assistant_button')}
            </div>
          </Button>
        </div>
        <FormViewer
          {...formViewerProps}
          bi={bi}
          biFormFillSessionId={formFillingSessionId}
        />
      </div>
    </div>
  );
};
